import React from "react"
import { Box as GBox } from "grommet"
import { graphql, useStaticQuery } from "gatsby"
import { List } from "./styles"
import WorkItem from "../../work-item"

const DataFragment = () => {
  const data = useStaticQuery(graphql`
      query allContentfulPortfolio {
          allContentfulPortfolio(filter: {node_locale: {eq: "en-US"}})  {
              edges {
                  node {
                      color
                      createdAt
                      featured
                      id
                      slug
                      title
                      updatedAt
                      externalLink
                      category {
                          title
                      }
                      featuredImage {
                          file {
                              url
                          }
                      }
                  }
              }
          }
      }
  `)

  return (
    <GBox fill style={{ minHeight: '70vh', height: 'auto' }}>
      <List>
        {data.allContentfulPortfolio.edges.map((item, index) => <WorkItem key={index} index={index} data={item.node} />)}
      </List>
    </GBox>
  )
}

export default DataFragment
