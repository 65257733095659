import { Box as GBox } from "grommet"
import React from "react"
import Footer from "../../footer"
import { colors } from "../../../theme"
import DataFragment from "./data-fragment"
import { SubTitle, Title } from "../home-fragments/rw-fragment.styles"

const WorkFragment = () => {
  return (
    <GBox fill pad={{ top: "large" }} style={{ height: 'auto', minWidth: '100%' }}>
      <GBox pad={{ left: ["xlarge"], top: 'xlarge', bottom: 'medium' }}>
        <GBox pad={{ left: "large" }}>
          <Title color={colors.black}>
            <span>My craftsmanship</span>
          </Title>
        </GBox>
        <GBox pad={{ left: "large", top: 'medium' }}>
          <SubTitle color={colors.black}>
            HERE ARE SOME OF MY WORKS
          </SubTitle>
        </GBox>
      </GBox>
      <DataFragment/>
      <GBox style={{ backgroundColor: colors.black}}>
        <Footer darkMode />
      </GBox>
    </GBox>
  )
}

export default WorkFragment
